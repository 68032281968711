/* @import '~antd/dist/antd.less'; */
body {
  font-family: 'Outfit', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0px;
}
code {
  font-family: 'Outfit', sans-serif;
}



