.res-logo {
    display: flex;
    flex-direction: row;
    position: absolute;
    left: 40px;
  }
  .res-logo-image {
    width: 80px;
  }
  
  .res-connect-btn {
    position: absolute;
    right: 50px;
  }
  .res-logo-text {
    font-size: 12px;
    font-weight: bold;
    font-family: Outfit;
    margin-top: 30px;
    color: #1e50e9;
  }
  .res-title-text {
    color: white;
    font-family: Outfit;
    font-weight: bold;
    font-size: 23px;

  }
  .res-content {
    padding: 20px 50px;
}
.res-header {
    background: transparent;
    padding-top: 50px;
    justify-content: space-between;
    height: 120px;
    margin-bottom: 30px;
}

.res-tab {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px;
    gap: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
}

  @media only screen and (max-width: 632px) {
    .res-logo {
      position: relative;
      font-size: 8px;
      left: 0px;

    }
    .res-connect-btn {
      position: absolute;
      right: 50px;
      top: 0px;

    }
    .res-logo-image{
      width: 60px;
    }
    .res-logo-text {
        font-size: 8px;
        margin-top: 10px;
    }
    .res-title-text {
        font-size: 20px;
        margin-top: 30px;
        color: white;
        position: absolute;
        margin-top: 36px;
        margin-left: 8px;

    }
    .res-content {
        padding:10px;
    }
    .res-header {
        background: transparent;
        padding-top: 30px;
        justify-content: space-between;
        height: 100px;
        margin-bottom: 20px;
    }

    .res-tab {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px;
        gap: 20px;
        margin-top: 10px;
        margin-bottom: 10px;
    }

  }

  .res-card {
    flex-direction: row;
    display: flex;
    align-items: center;
    align-content: center;
    align-self: center;
    color:white;
    gap: 3px;
  }
  @media screen and (max-width: 470px) {
    .res-card{
        flex-direction: column;
        display: flex;
        align-items: center;
        align-content: center;
        color:white;

    }
  }
  