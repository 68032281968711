/* Remove the default focus outline for the tabs */
.ant-tabs-tab-btn:focus {
  outline: none !important; /* Force remove the outline */
  box-shadow: none !important; /* Remove any shadow */
}

/* Remove the focus-visible outline completely */
.ant-tabs-tab-btn:focus-visible {
  outline: none !important; /* Force remove outline on keyboard focus */
  box-shadow: none !important; /* Force remove shadow */
}

.custom-input::placeholder {
  font-size: 18px !important; /* Customize font size */
  opacity: 1 !important; /* Ensure full visibility */
  font-family: 'Outfit'!important;
}

.ant-notification-notice {
  font-family: 'Outfit' !important;
}
